import OcrTipSection1 from './ocr-tip-section-1.png';
import OcrTipSection2 from './ocr-tip-section-2.png';
import OcrTipSection3 from './ocr-tip-section-3.png';
import OcrTipSection4 from './ocr-tip-section-4.png';
import Matchday from './matchday.png';
import WinLose from './win-lose.png';
import SeatReservation from './seat-reservation.png';
import RentalGoods from './rental-goods.png';
import MVP from './mvp.png';
import AfterReport from './after-report.png';
import PostMessage from './post-message.png';
import SuggestionBox from './suggestion-box.png';
import SeatReservationDetail from './seat-reservation-detail.png';
import OcrEntranceThumbnail from './ocr-scan-entrance-thumb.png';
import OcrScanEntranceThumbnail from './ocr-scan-entrance-thumb.svg';
import TopPageThumbnail from './top-page-thumbnail.png';

import TicketScan from './ticket-scan.svg';
import UserRegistrationSuccess from './user-registration-success.svg';

export const images = {
  OcrTipSection1,
  OcrTipSection2,
  OcrTipSection3,
  OcrTipSection4,
  Matchday,
  WinLose,
  SeatReservation,
  RentalGoods,
  MVP,
  AfterReport,
  PostMessage,
  SuggestionBox,
  SeatReservationDetail,
  OcrEntranceThumbnail,
  TopPageThumbnail,
};

export { TicketScan, UserRegistrationSuccess, OcrScanEntranceThumbnail };
