import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
};

export type AdminAttributeEntity = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AdminCreateDigitalCertificateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enable: Enable;
  eventEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  eventPlaceName?: InputMaybe<Scalars['String']['input']>;
  eventStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  highlightId?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminCreateHighlightInput = {
  highlightDescription?: InputMaybe<Scalars['String']['input']>;
  highlightImages?: InputMaybe<Array<AdminHighlightImageInput>>;
  highlightTitle?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateProjectInput = {
  attributes: Array<AdminAttributeEntity>;
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminCreateSeatReservationInput = {
  arenaSideLimit2thFloor: Scalars['Float']['input'];
  eventDate: Scalars['ISO8601DateTime']['input'];
  floorLimit3rd: Scalars['Float']['input'];
};

export type AdminCreateTicketInput = {
  beforeDropDescription: Scalars['String']['input'];
  consumeableEndAt: Scalars['ISO8601DateTime']['input'];
  consumeableStartAt: Scalars['ISO8601DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dropEndAt: Scalars['ISO8601DateTime']['input'];
  dropStartAt: Scalars['ISO8601DateTime']['input'];
  enable: Scalars['Boolean']['input'];
  eventEndAt: Scalars['ISO8601DateTime']['input'];
  eventPlaceName: Scalars['String']['input'];
  eventStartAt: Scalars['ISO8601DateTime']['input'];
  highlightId?: InputMaybe<Scalars['Float']['input']>;
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectNumber?: InputMaybe<Scalars['Float']['input']>;
  stockedCount: Scalars['Float']['input'];
};

export type AdminDigitalCertificateEntity = {
  __typename?: 'AdminDigitalCertificateEntity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enable: Enable;
  eventEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eventPlaceName?: Maybe<Scalars['String']['output']>;
  eventStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  highlight?: Maybe<AdminHighlight>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  ocrHistory?: Maybe<OcrHistoryEntity>;
  projectId?: Maybe<Scalars['Int']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  uniqueKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userCount?: Maybe<Scalars['Int']['output']>;
};

export type AdminHighlight = {
  __typename?: 'AdminHighlight';
  createdAt: Scalars['ISO8601DateTime']['output'];
  highlightTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdminHighlightEntity = {
  __typename?: 'AdminHighlightEntity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  highlightDescription?: Maybe<Scalars['String']['output']>;
  highlightImages?: Maybe<Array<HighlightImageEntity>>;
  highlightTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdminHighlightImageInput = {
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AdminProjectEntity = {
  __typename?: 'AdminProjectEntity';
  attributes: Array<AttributeEntity>;
  certificate?: Maybe<AdminDigitalCertificateEntity>;
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isHaveUserCertificate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['Float']['output'];
};

export type AdminSeatReservationEntity = {
  __typename?: 'AdminSeatReservationEntity';
  arenaSideLimit2thFloor: Scalars['Float']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventDate: Scalars['ISO8601DateTime']['output'];
  floorLimit3rd: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
};

export type AdminTicketEntity = {
  __typename?: 'AdminTicketEntity';
  beforeDropDescription: Scalars['String']['output'];
  consumeableEndAt: Scalars['ISO8601DateTime']['output'];
  consumeableStartAt: Scalars['ISO8601DateTime']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dropEndAt: Scalars['ISO8601DateTime']['output'];
  dropStartAt: Scalars['ISO8601DateTime']['output'];
  droppableCount: Scalars['Float']['output'];
  droppedCount: Scalars['Float']['output'];
  enable: Scalars['Boolean']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  eventPlaceName: Scalars['String']['output'];
  eventStartAt: Scalars['ISO8601DateTime']['output'];
  highlight?: Maybe<HighlightEntity>;
  highlightId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  projectNumber?: Maybe<Scalars['Float']['output']>;
  stockedCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdminUpdateDigitalCertificateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enable?: InputMaybe<Enable>;
  eventEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  eventPlaceName?: InputMaybe<Scalars['String']['input']>;
  eventStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  highlightId?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  uniqueKey: Scalars['String']['input'];
};

export type AdminUpdateHighlightInput = {
  highlightDescription?: InputMaybe<Scalars['String']['input']>;
  highlightImages?: InputMaybe<Array<AdminHighlightImageInput>>;
  highlightTitle?: InputMaybe<Scalars['String']['input']>;
  uniqueKey: Scalars['String']['input'];
};

export type AdminUpdateSeatReservationInput = {
  arenaSideLimit2thFloor: Scalars['Float']['input'];
  eventDate: Scalars['ISO8601DateTime']['input'];
  floorLimit3rd: Scalars['Float']['input'];
};

export type AdminUpdateTicketInput = {
  beforeDropDescription?: InputMaybe<Scalars['String']['input']>;
  consumeableEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  consumeableStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dropEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dropStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  eventEndAt: Scalars['ISO8601DateTime']['input'];
  eventPlaceName?: InputMaybe<Scalars['String']['input']>;
  eventStartAt: Scalars['ISO8601DateTime']['input'];
  highlightId?: InputMaybe<Scalars['Float']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectNumber?: InputMaybe<Scalars['Float']['input']>;
  stockedCount?: InputMaybe<Scalars['Float']['input']>;
  uniqueKey: Scalars['String']['input'];
};

export type AdminUpdatedProjectInput = {
  attributes: Array<AdminAttributeEntity>;
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Float']['input'];
};

export type AdminUserEntity = {
  __typename?: 'AdminUserEntity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirthOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eoaAddress: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdminUserSeatReservationEntity = {
  __typename?: 'AdminUserSeatReservationEntity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventDate: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Float']['output'];
  isAdminCancel: Scalars['Boolean']['output'];
  seatType: SeatType;
  uniqueKey: Scalars['String']['output'];
  user: AdminUserEntity;
};

export type AdminUserTicketEntity = {
  __typename?: 'AdminUserTicketEntity';
  awareness?: Maybe<Scalars['String']['output']>;
  childrenAges?: Maybe<Scalars['String']['output']>;
  childrenCount?: Maybe<Scalars['Float']['output']>;
  consumedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Float']['output'];
  maritalStatus?: Maybe<Scalars['String']['output']>;
  nftOpenSeaUrl?: Maybe<Scalars['String']['output']>;
  nftTransferExecStatus: Scalars['String']['output'];
  nftTransferTxCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  nftTransferTxHash?: Maybe<Scalars['String']['output']>;
  preferredTicketType?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  ticket: TicketEntity;
  transferAttemptCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: UserEntity;
  visitCount?: Maybe<Scalars['Float']['output']>;
};

export type AttributeEntity = {
  __typename?: 'AttributeEntity';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BenefitEntity = {
  __typename?: 'BenefitEntity';
  eventDate: Scalars['ISO8601DateTime']['output'];
  eventStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Float']['output'];
  iframeUrl: Scalars['String']['output'];
  isOcr?: Maybe<Scalars['Boolean']['output']>;
  isTicket?: Maybe<Scalars['Boolean']['output']>;
  ocrHistory?: Maybe<OcrHistoryEntity>;
  opponentTeamName?: Maybe<Scalars['String']['output']>;
};

export type BenefitGoodsEntity = {
  __typename?: 'BenefitGoodsEntity';
  certificateUniqueKey?: Maybe<Scalars['String']['output']>;
  eventDate: Scalars['ISO8601DateTime']['output'];
  eventStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOcr?: Maybe<Scalars['Boolean']['output']>;
  isTicket?: Maybe<Scalars['Boolean']['output']>;
  ocrHistory?: Maybe<OcrHistoryEntity>;
  opponentTeamName?: Maybe<Scalars['String']['output']>;
  ticketUniqueKey?: Maybe<Scalars['String']['output']>;
};

export type BenefitSeatReservationEntity = {
  __typename?: 'BenefitSeatReservationEntity';
  certificateUniqueKey?: Maybe<Scalars['String']['output']>;
  eventDate: Scalars['ISO8601DateTime']['output'];
  eventStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOcr?: Maybe<Scalars['Boolean']['output']>;
  isTicket?: Maybe<Scalars['Boolean']['output']>;
  ocrHistory?: Maybe<OcrHistoryEntity>;
  opponentTeamName?: Maybe<Scalars['String']['output']>;
};

/** The type of benefit settings */
export enum BenefitType {
  CheerSettings = 'CHEER_SETTINGS',
  EventFeedbackSettings = 'EVENT_FEEDBACK_SETTINGS',
  EventInfoSettings = 'EVENT_INFO_SETTINGS',
  EventReportSettings = 'EVENT_REPORT_SETTINGS',
  GamePredictionSettings = 'GAME_PREDICTION_SETTINGS',
  GoodsRentalSettings = 'GOODS_RENTAL_SETTINGS',
  MvpVotingSettings = 'MVP_VOTING_SETTINGS',
  SeatReservationSettings = 'SEAT_RESERVATION_SETTINGS'
}

export type CreateUserCertificateInput = {
  awareness?: InputMaybe<Scalars['String']['input']>;
  certificateId: Scalars['Int']['input'];
  relationship?: InputMaybe<Scalars['String']['input']>;
  visitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserInput = {
  dateOfBirthOn?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserSeatReservationInput = {
  eventDate: Scalars['ISO8601DateTime']['input'];
  seatType: Scalars['String']['input'];
};

export type CreateUserTicketInput = {
  awareness?: InputMaybe<Scalars['String']['input']>;
  childrenAges?: InputMaybe<Scalars['String']['input']>;
  childrenCount?: InputMaybe<Scalars['Float']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  nftTransferExecStatus?: InputMaybe<NftTransferExecStatus>;
  nftTransferTxCheckedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  nftTransferTxHash?: InputMaybe<Scalars['String']['input']>;
  preferredTicketType?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  ticketUniqueKey: Scalars['String']['input'];
  tokenNumber?: InputMaybe<Scalars['Float']['input']>;
  transferAttemptCount?: InputMaybe<Scalars['Float']['input']>;
  visitCount?: InputMaybe<Scalars['Float']['input']>;
};

/** The status of the a record */
export enum Enable {
  False = 'FALSE',
  True = 'TRUE'
}

export type EventGoodsEntity = {
  __typename?: 'EventGoodsEntity';
  eventRentalGoodId: Scalars['Float']['output'];
  good: GoodsEntity;
  goodsRemaining: Scalars['Float']['output'];
  isRented: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['Float']['output']>;
  userRentalGoodId?: Maybe<Scalars['Float']['output']>;
};

export type EventRentalGoodsEntity = {
  __typename?: 'EventRentalGoodsEntity';
  eventDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  good?: Maybe<GoodsEntity>;
  goodId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
};

export type GoodsEntity = {
  __typename?: 'GoodsEntity';
  id: Scalars['Float']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type HighlightEntity = {
  __typename?: 'HighlightEntity';
  highlightDescription?: Maybe<Scalars['String']['output']>;
  highlightImages?: Maybe<Array<HighlightImageEntity>>;
  highlightTitle?: Maybe<Scalars['String']['output']>;
  uniqueKey: Scalars['String']['output'];
};

export type HighlightImageEntity = {
  __typename?: 'HighlightImageEntity';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ListUserSeatReservationDto = {
  currentPage?: Scalars['Int']['input'];
  eventDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  limit?: Scalars['Int']['input'];
  seatType?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Admin: Create Digital Certificate */
  adminCreateDigitalCertificate: AdminDigitalCertificateEntity;
  adminCreateHighlight: AdminHighlightEntity;
  adminCreateProject: AdminProjectEntity;
  adminCreateSeatReservationSetting: AdminSeatReservationEntity;
  adminCreateTicket: AdminTicketEntity;
  adminDeleteHighlight: AdminHighlightEntity;
  adminDeleteUserSeatReservation: AdminUserSeatReservationEntity;
  /** Admin: Update Digital Certificate */
  adminUpdateDigitalCertificate: AdminDigitalCertificateEntity;
  adminUpdateHighlight: AdminHighlightEntity;
  adminUpdateProject: AdminProjectEntity;
  adminUpdateSeatReservationSetting: AdminSeatReservationEntity;
  adminUpdateTicket: AdminTicketEntity;
  cancelUserRentalGoods: UserRentalGoodsEntity;
  cancelUserSeatReservation: UserSeatReservationEntity;
  consumeUserTicket: UserTicketEntity;
  createUser: UserEntity;
  createUserCertificate: UserCertificateEntity;
  createUserGoodRental: UserRentalGoodsEntity;
  createUserSeatReservation: UserSeatReservationEntity;
  createUserTicket: UserTicketEntity;
};


export type MutationAdminCreateDigitalCertificateArgs = {
  adminCreateDigitalCertificateInput: AdminCreateDigitalCertificateInput;
};


export type MutationAdminCreateHighlightArgs = {
  AdminCreateHighlightInput: AdminCreateHighlightInput;
};


export type MutationAdminCreateProjectArgs = {
  adminCreateProjectInput: AdminCreateProjectInput;
};


export type MutationAdminCreateSeatReservationSettingArgs = {
  AdminCreateSeatReservationInput: AdminCreateSeatReservationInput;
};


export type MutationAdminCreateTicketArgs = {
  adminCreateTicketInput: AdminCreateTicketInput;
};


export type MutationAdminDeleteHighlightArgs = {
  highlightUniqueKey: Scalars['String']['input'];
};


export type MutationAdminDeleteUserSeatReservationArgs = {
  userSeatReservationUniqueKey: Scalars['String']['input'];
};


export type MutationAdminUpdateDigitalCertificateArgs = {
  adminUpdateDigitalCertificateInput: AdminUpdateDigitalCertificateInput;
};


export type MutationAdminUpdateHighlightArgs = {
  AdminUpdateHighlightInput: AdminUpdateHighlightInput;
};


export type MutationAdminUpdateProjectArgs = {
  AdminUpdateProjectInput: AdminUpdatedProjectInput;
};


export type MutationAdminUpdateSeatReservationSettingArgs = {
  AdminUpdateSeatReservationInput: AdminUpdateSeatReservationInput;
  seatReservationUniqueKey: Scalars['String']['input'];
};


export type MutationAdminUpdateTicketArgs = {
  adminUpdateTicketInput: AdminUpdateTicketInput;
};


export type MutationCancelUserRentalGoodsArgs = {
  certificateUniqueKey?: InputMaybe<Scalars['String']['input']>;
  ticketUniqueKey?: InputMaybe<Scalars['String']['input']>;
  userRentalGoodId: Scalars['Float']['input'];
};


export type MutationCancelUserSeatReservationArgs = {
  certificateUniqueKey: Scalars['String']['input'];
  seatReservationUniqueKey: Scalars['String']['input'];
};


export type MutationConsumeUserTicketArgs = {
  userTicketUniqueKey: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserCertificateArgs = {
  input: CreateUserCertificateInput;
};


export type MutationCreateUserGoodRentalArgs = {
  eventRentalGoodId: Scalars['Float']['input'];
};


export type MutationCreateUserSeatReservationArgs = {
  certificateUniqueKey: Scalars['String']['input'];
  input: CreateUserSeatReservationInput;
};


export type MutationCreateUserTicketArgs = {
  input: CreateUserTicketInput;
};

export enum NftTransferExecStatus {
  Idle = 'IDLE',
  ServerQueued = 'SERVER_QUEUED',
  TxCompleted = 'TX_COMPLETED',
  TxFailed = 'TX_FAILED',
  TxIssued = 'TX_ISSUED',
  TxStucking = 'TX_STUCKING'
}

export type OcrHistoryEntity = {
  __typename?: 'OcrHistoryEntity';
  category: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  matchDate: Scalars['ISO8601DateTime']['output'];
  matchLocation: Scalars['String']['output'];
  opponent: Scalars['String']['output'];
  seatType: Scalars['String']['output'];
};

export type Paging = {
  __typename?: 'Paging';
  currentPage: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
  pages: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PagingDigitalCertificate = {
  __typename?: 'PagingDigitalCertificate';
  data: Array<AdminDigitalCertificateEntity>;
  meta: Paging;
};

export type PagingHighlight = {
  __typename?: 'PagingHighlight';
  data: Array<AdminHighlightEntity>;
  meta: Paging;
};

export type PagingProject = {
  __typename?: 'PagingProject';
  data: Array<AdminProjectEntity>;
  meta: Paging;
};

export type PagingSeatReservation = {
  __typename?: 'PagingSeatReservation';
  data: Array<AdminSeatReservationEntity>;
  meta: Paging;
};

export type PagingTicket = {
  __typename?: 'PagingTicket';
  data: Array<AdminTicketEntity>;
  meta: Paging;
};

export type PagingUserCertificates = {
  __typename?: 'PagingUserCertificates';
  data: Array<UserCertificateEntity>;
  meta: Paging;
};

export type PagingUserSeatReservation = {
  __typename?: 'PagingUserSeatReservation';
  data: Array<AdminUserSeatReservationEntity>;
  meta: Paging;
};

export type PagingUserTicket = {
  __typename?: 'PagingUserTicket';
  data: Array<AdminUserTicketEntity>;
  meta: Paging;
};

export type Query = {
  __typename?: 'Query';
  /** Admin: Get All Digital Certificates */
  adminGetAllDigitalCertificates: PagingDigitalCertificate;
  /** Admin: Get Digital Certificate Detail */
  adminGetDigitalCertificateDetail: AdminDigitalCertificateEntity;
  /** Admin: Get List User Digital Certificates */
  adminGetListUserCertificates: PagingUserCertificates;
  adminHighlight: AdminHighlightEntity;
  adminHighlights: PagingHighlight;
  adminHighlightsAll: Array<AdminHighlightEntity>;
  adminLogin: SignInResponse;
  adminLogout: Scalars['String']['output'];
  adminProject: AdminProjectEntity;
  adminProjects: Array<AdminProjectEntity>;
  adminProjectsPaging: PagingProject;
  adminSeatReservationSetting: AdminSeatReservationEntity;
  adminSeatReservationSettings: PagingSeatReservation;
  adminTicket: AdminTicketEntity;
  adminTickets: PagingTicket;
  adminUserSeatReservation: AdminUserSeatReservationEntity;
  adminUserSeatReservations: PagingUserSeatReservation;
  adminUserTickets: PagingUserTicket;
  benefitCommonCertificate: Array<BenefitEntity>;
  checkBatchMintButton: Scalars['Boolean']['output'];
  checkBatchMintCertificateButton: Scalars['Boolean']['output'];
  droppableUserTicket: Scalars['Boolean']['output'];
  getBenefitRentalGoods: Array<BenefitGoodsEntity>;
  getBenefitSeatReservation: Array<BenefitSeatReservationEntity>;
  ticket: TicketEntity;
  user: UserEntity;
  /** User: Get All Digital Certificates */
  userGetAllDigitalCertificates: Array<UserCertificateEntity>;
  /** User: Get Digital Certificate Detail */
  userGetDigitalCertificateDetail: UserCertificateEntity;
  userGoodRentals?: Maybe<UserEventRentalGoodsEntity>;
  userSeatReservations?: Maybe<UserSeatReservation>;
  userTicket: UserTicketEntity;
  userTickets: Array<UserTicketEntity>;
};


export type QueryAdminGetAllDigitalCertificatesArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminGetDigitalCertificateDetailArgs = {
  uniqueKey: Scalars['String']['input'];
};


export type QueryAdminGetListUserCertificatesArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
  uniqueKey: Scalars['String']['input'];
};


export type QueryAdminHighlightArgs = {
  highlightUniqueKey: Scalars['String']['input'];
};


export type QueryAdminHighlightsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type QueryAdminProjectArgs = {
  projectId: Scalars['Float']['input'];
};


export type QueryAdminProjectsPagingArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminSeatReservationSettingArgs = {
  seatReservationUniqueKey: Scalars['String']['input'];
};


export type QueryAdminSeatReservationSettingsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryAdminTicketsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
};


export type QueryAdminUserSeatReservationArgs = {
  userSeatReservationUniqueKey: Scalars['String']['input'];
};


export type QueryAdminUserSeatReservationsArgs = {
  input: ListUserSeatReservationDto;
};


export type QueryAdminUserTicketsArgs = {
  currentPage: Scalars['Float']['input'];
  limit: Scalars['Float']['input'];
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryBenefitCommonCertificateArgs = {
  type: BenefitType;
};


export type QueryCheckBatchMintButtonArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryCheckBatchMintCertificateButtonArgs = {
  certificateUniqueKey: Scalars['String']['input'];
};


export type QueryDroppableUserTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryTicketArgs = {
  ticketUniqueKey: Scalars['String']['input'];
};


export type QueryUserGetDigitalCertificateDetailArgs = {
  uniqueKey: Scalars['String']['input'];
};


export type QueryUserGoodRentalsArgs = {
  certificateUniqueKey?: InputMaybe<Scalars['String']['input']>;
  ticketUniqueKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserSeatReservationsArgs = {
  certificateUniqueKey: Scalars['String']['input'];
};


export type QueryUserTicketArgs = {
  userTicketUniqueKey: Scalars['String']['input'];
};

/** The type of seat */
export enum SeatType {
  ArenaSide = 'ARENA_SIDE',
  CourtSide = 'COURT_SIDE',
  ExcitingEast = 'EXCITING_EAST',
  ExcitingWest = 'EXCITING_WEST',
  FloorLimit_3Rd = 'FLOOR_LIMIT_3RD'
}

export type SignInResponse = {
  __typename?: 'SignInResponse';
  accessToken: Scalars['String']['output'];
  expiresAt: Scalars['Float']['output'];
  expiresIn: Scalars['Float']['output'];
  tokenType: Scalars['String']['output'];
};

export type TicketEntity = {
  __typename?: 'TicketEntity';
  beforeDropDescription: Scalars['String']['output'];
  consumeableEndAt: Scalars['ISO8601DateTime']['output'];
  consumeableStartAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  dropEndAt: Scalars['ISO8601DateTime']['output'];
  dropStartAt: Scalars['ISO8601DateTime']['output'];
  droppableCount: Scalars['Float']['output'];
  droppedCount: Scalars['Float']['output'];
  enable: Scalars['Boolean']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  eventPlaceName: Scalars['String']['output'];
  eventStartAt: Scalars['ISO8601DateTime']['output'];
  highlight?: Maybe<HighlightEntity>;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  projectNumber?: Maybe<Scalars['Float']['output']>;
  stockedCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
};

export type UserCertificateEntity = {
  __typename?: 'UserCertificateEntity';
  awareness?: Maybe<Scalars['String']['output']>;
  certificate?: Maybe<AdminDigitalCertificateEntity>;
  consumedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dropNftStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  nftOpenSeaUrl?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  txHash?: Maybe<Scalars['String']['output']>;
  uniqueKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user?: Maybe<UserEntity>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  dateOfBirthOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eoaAddress: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  uniqueKey: Scalars['String']['output'];
};

export type UserEventRentalGoodsEntity = {
  __typename?: 'UserEventRentalGoodsEntity';
  eventDate: Scalars['ISO8601DateTime']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  eventGoods: Array<EventGoodsEntity>;
};

export type UserRentalGoodsEntity = {
  __typename?: 'UserRentalGoodsEntity';
  eventDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eventRentalGoodId: Scalars['Float']['output'];
  eventRentalGoods?: Maybe<EventRentalGoodsEntity>;
  id: Scalars['Float']['output'];
  user?: Maybe<UserEntity>;
  userId: Scalars['Float']['output'];
};

export type UserSeatReservation = {
  __typename?: 'UserSeatReservation';
  eventDate: Scalars['ISO8601DateTime']['output'];
  eventEndAt: Scalars['ISO8601DateTime']['output'];
  isAdminCancel: Scalars['Boolean']['output'];
  isReserved: Scalars['Boolean']['output'];
  seatRemaining: Scalars['Float']['output'];
  seatType: SeatType;
  userSeatReservation?: Maybe<UserSeatReservationEntity>;
};

export type UserSeatReservationEntity = {
  __typename?: 'UserSeatReservationEntity';
  eventDate: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Float']['output'];
  isAdminCancel: Scalars['Boolean']['output'];
  seatType: Scalars['String']['output'];
  uniqueKey: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type UserTicketEntity = {
  __typename?: 'UserTicketEntity';
  awareness?: Maybe<Scalars['String']['output']>;
  childrenAges?: Maybe<Scalars['String']['output']>;
  childrenCount?: Maybe<Scalars['Float']['output']>;
  consumedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  nftOpenSeaUrl?: Maybe<Scalars['String']['output']>;
  nftTransferExecStatus: NftTransferExecStatus;
  nftTransferTxCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  nftTransferTxHash?: Maybe<Scalars['String']['output']>;
  preferredTicketType?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  ticket: TicketEntity;
  tokenNumber?: Maybe<Scalars['Float']['output']>;
  transferAttemptCount: Scalars['Float']['output'];
  uniqueKey: Scalars['String']['output'];
  user: UserEntity;
  visitCount?: Maybe<Scalars['Float']['output']>;
};

export type BenefitCommonFieldFragment = { __typename?: 'BenefitEntity', id: number, eventDate: any, eventStartAt?: any | null, iframeUrl: string, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', id: number, opponent: string, matchLocation: string, seatType: string, matchDate: any, category: string } | null };

export type BenefitsRentalGoodFieldFragment = { __typename?: 'BenefitGoodsEntity', eventDate: any, certificateUniqueKey?: string | null, ticketUniqueKey?: string | null, eventStartAt?: any | null, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', opponent: string, seatType: string, matchDate: any } | null };

export type BenefitSeatReservationFieldFragment = { __typename?: 'BenefitSeatReservationEntity', eventDate: any, certificateUniqueKey?: string | null, eventStartAt?: any | null, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', opponent: string, seatType: string, matchDate: any } | null };

export type TicketFieldFragment = { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, enable: boolean, dropStartAt: any, dropEndAt: any, consumeableStartAt: any, consumeableEndAt: any, stockedCount: number, droppedCount: number, droppableCount: number, projectNumber?: number | null, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null };

export type UserDigitalCertificateFieldFragment = { __typename?: 'UserCertificateEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, dropNftStatus?: string | null, consumedAt?: any | null, certificate?: { __typename?: 'AdminDigitalCertificateEntity', uniqueKey: string, name: string, description?: string | null, imageUrl?: string | null, eventPlaceName?: string | null, eventStartAt?: any | null, eventEndAt?: any | null, enable: Enable } | null };

export type UserGoodRentalsFieldFragment = { __typename?: 'UserEventRentalGoodsEntity', eventDate: any, eventEndAt: any, eventGoods: Array<{ __typename?: 'EventGoodsEntity', eventRentalGoodId: number, userRentalGoodId?: number | null, isRented: boolean, goodsRemaining: number, good: { __typename?: 'GoodsEntity', id: number, name: string, imageUrl: string } }> };

export type UserSeatReservationFieldFragment = { __typename?: 'UserSeatReservation', eventDate: any, seatType: SeatType, seatRemaining: number, isReserved: boolean, isAdminCancel: boolean, eventEndAt: any, userSeatReservation?: { __typename?: 'UserSeatReservationEntity', uniqueKey: string } | null };

export type UserTicketFieldFragment = { __typename?: 'UserTicketEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, nftTransferExecStatus: NftTransferExecStatus, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } };

export type UserFieldFragment = { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string };

export type CancelUserRentalGoodsMutationVariables = Exact<{
  userRentalGoodId: Scalars['Float']['input'];
  certificateUniqueKey: Scalars['String']['input'];
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type CancelUserRentalGoodsMutation = { __typename?: 'Mutation', cancelUserRentalGoods: { __typename?: 'UserRentalGoodsEntity', id: number, eventRentalGoodId: number, userId: number } };

export type CancelUserSeatReservationMutationVariables = Exact<{
  seatReservationUniqueKey: Scalars['String']['input'];
  certificateUniqueKey: Scalars['String']['input'];
}>;


export type CancelUserSeatReservationMutation = { __typename?: 'Mutation', cancelUserSeatReservation: { __typename?: 'UserSeatReservationEntity', id: number, uniqueKey: string, eventDate: any, seatType: string, userId: number, isAdminCancel: boolean } };

export type ConsumeUserTicketMutationVariables = Exact<{
  userTicketUniqueKey: Scalars['String']['input'];
}>;


export type ConsumeUserTicketMutation = { __typename?: 'Mutation', consumeUserTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, nftTransferExecStatus: NftTransferExecStatus, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } } };

export type CreateUserCertificateMutationVariables = Exact<{
  input: CreateUserCertificateInput;
}>;


export type CreateUserCertificateMutation = { __typename?: 'Mutation', createUserCertificate: { __typename?: 'UserCertificateEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, dropNftStatus?: string | null, consumedAt?: any | null, certificate?: { __typename?: 'AdminDigitalCertificateEntity', uniqueKey: string, name: string, description?: string | null, imageUrl?: string | null, eventPlaceName?: string | null, eventStartAt?: any | null, eventEndAt?: any | null, enable: Enable } | null } };

export type CreateUserGoodRentalMutationVariables = Exact<{
  eventRentalGoodId: Scalars['Float']['input'];
}>;


export type CreateUserGoodRentalMutation = { __typename?: 'Mutation', createUserGoodRental: { __typename?: 'UserRentalGoodsEntity', id: number, eventRentalGoodId: number, userId: number } };

export type CreateUserSeatReservationMutationVariables = Exact<{
  certificateUniqueKey: Scalars['String']['input'];
  input: CreateUserSeatReservationInput;
}>;


export type CreateUserSeatReservationMutation = { __typename?: 'Mutation', createUserSeatReservation: { __typename?: 'UserSeatReservationEntity', id: number, uniqueKey: string, eventDate: any, seatType: string, userId: number, isAdminCancel: boolean } };

export type CreateUserTicketMutationVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
  visitCount?: InputMaybe<Scalars['Float']['input']>;
  awareness?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  childrenCount?: InputMaybe<Scalars['Float']['input']>;
  childrenAges?: InputMaybe<Scalars['String']['input']>;
  preferredTicketType?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateUserTicketMutation = { __typename?: 'Mutation', createUserTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, nftTransferExecStatus: NftTransferExecStatus, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } } };

export type CreateUserMutationVariables = Exact<{
  nickname: Scalars['String']['input'];
  dateOfBirthOn: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  gender: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string } };

export type BenefitCommonCertificateQueryVariables = Exact<{
  type: BenefitType;
}>;


export type BenefitCommonCertificateQuery = { __typename?: 'Query', benefitCommonCertificate: Array<{ __typename?: 'BenefitEntity', id: number, eventDate: any, eventStartAt?: any | null, iframeUrl: string, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', id: number, opponent: string, matchLocation: string, seatType: string, matchDate: any, category: string } | null }> };

export type DroppableUserTicketQueryVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type DroppableUserTicketQuery = { __typename?: 'Query', droppableUserTicket: boolean };

export type GetBenefitRentalGoodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBenefitRentalGoodsQuery = { __typename?: 'Query', getBenefitRentalGoods: Array<{ __typename?: 'BenefitGoodsEntity', eventDate: any, certificateUniqueKey?: string | null, ticketUniqueKey?: string | null, eventStartAt?: any | null, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', opponent: string, seatType: string, matchDate: any } | null }> };

export type GetBenefitSeatReservationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBenefitSeatReservationQuery = { __typename?: 'Query', getBenefitSeatReservation: Array<{ __typename?: 'BenefitSeatReservationEntity', eventDate: any, certificateUniqueKey?: string | null, eventStartAt?: any | null, isOcr?: boolean | null, isTicket?: boolean | null, opponentTeamName?: string | null, ocrHistory?: { __typename?: 'OcrHistoryEntity', opponent: string, seatType: string, matchDate: any } | null }> };

export type TicketQueryVariables = Exact<{
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type TicketQuery = { __typename?: 'Query', ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, enable: boolean, dropStartAt: any, dropEndAt: any, consumeableStartAt: any, consumeableEndAt: any, stockedCount: number, droppedCount: number, droppableCount: number, projectNumber?: number | null, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } };

export type UserGetDigitalCertificateQueryVariables = Exact<{
  uniqueKey: Scalars['String']['input'];
}>;


export type UserGetDigitalCertificateQuery = { __typename?: 'Query', userGetDigitalCertificateDetail: { __typename?: 'UserCertificateEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, dropNftStatus?: string | null, consumedAt?: any | null, certificate?: { __typename?: 'AdminDigitalCertificateEntity', uniqueKey: string, name: string, description?: string | null, imageUrl?: string | null, eventPlaceName?: string | null, eventStartAt?: any | null, eventEndAt?: any | null, enable: Enable } | null } };

export type UserDigitalCertificatesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDigitalCertificatesQuery = { __typename?: 'Query', userGetAllDigitalCertificates: Array<{ __typename?: 'UserCertificateEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, dropNftStatus?: string | null, consumedAt?: any | null, certificate?: { __typename?: 'AdminDigitalCertificateEntity', uniqueKey: string, name: string, description?: string | null, imageUrl?: string | null, eventPlaceName?: string | null, eventStartAt?: any | null, eventEndAt?: any | null, enable: Enable } | null }> };

export type UserGoodRentalsQueryVariables = Exact<{
  certificateUniqueKey: Scalars['String']['input'];
  ticketUniqueKey: Scalars['String']['input'];
}>;


export type UserGoodRentalsQuery = { __typename?: 'Query', userGoodRentals?: { __typename?: 'UserEventRentalGoodsEntity', eventDate: any, eventEndAt: any, eventGoods: Array<{ __typename?: 'EventGoodsEntity', eventRentalGoodId: number, userRentalGoodId?: number | null, isRented: boolean, goodsRemaining: number, good: { __typename?: 'GoodsEntity', id: number, name: string, imageUrl: string } }> } | null };

export type UserSeatReservationsQueryVariables = Exact<{
  certificateUniqueKey: Scalars['String']['input'];
}>;


export type UserSeatReservationsQuery = { __typename?: 'Query', userSeatReservations?: { __typename?: 'UserSeatReservation', eventDate: any, seatType: SeatType, seatRemaining: number, isReserved: boolean, isAdminCancel: boolean, eventEndAt: any, userSeatReservation?: { __typename?: 'UserSeatReservationEntity', uniqueKey: string } | null } | null };

export type UserTicketQueryVariables = Exact<{
  userTicketUniqueKey: Scalars['String']['input'];
}>;


export type UserTicketQuery = { __typename?: 'Query', userTicket: { __typename?: 'UserTicketEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, nftTransferExecStatus: NftTransferExecStatus, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } } };

export type UserTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTicketsQuery = { __typename?: 'Query', userTickets: Array<{ __typename?: 'UserTicketEntity', uniqueKey: string, nftOpenSeaUrl?: string | null, nftTransferExecStatus: NftTransferExecStatus, consumedAt?: any | null, ticket: { __typename?: 'TicketEntity', uniqueKey: string, name: string, description: string, beforeDropDescription: string, imageUrl: string, eventPlaceName: string, eventStartAt: any, eventEndAt: any, consumeableStartAt: any, consumeableEndAt: any, enable: boolean, highlight?: { __typename?: 'HighlightEntity', uniqueKey: string, highlightTitle?: string | null, highlightDescription?: string | null, highlightImages?: Array<{ __typename?: 'HighlightImageEntity', url: string, type: string }> | null } | null } }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserEntity', uniqueKey: string, eoaAddress: string } };

export const BenefitCommonFieldFragmentDoc = gql`
    fragment BenefitCommonField on BenefitEntity {
  id
  eventDate
  eventStartAt
  iframeUrl
  isOcr
  isTicket
  opponentTeamName
  ocrHistory {
    id
    opponent
    matchLocation
    seatType
    matchDate
    category
  }
}
    `;
export const BenefitsRentalGoodFieldFragmentDoc = gql`
    fragment BenefitsRentalGoodField on BenefitGoodsEntity {
  eventDate
  certificateUniqueKey
  ticketUniqueKey
  eventStartAt
  isOcr
  isTicket
  opponentTeamName
  ocrHistory {
    opponent
    seatType
    matchDate
  }
}
    `;
export const BenefitSeatReservationFieldFragmentDoc = gql`
    fragment BenefitSeatReservationField on BenefitSeatReservationEntity {
  eventDate
  certificateUniqueKey
  eventStartAt
  isOcr
  isTicket
  opponentTeamName
  ocrHistory {
    opponent
    seatType
    matchDate
  }
}
    `;
export const TicketFieldFragmentDoc = gql`
    fragment TicketField on TicketEntity {
  uniqueKey
  name
  description
  beforeDropDescription
  imageUrl
  eventPlaceName
  eventStartAt
  eventEndAt
  enable
  dropStartAt
  dropEndAt
  consumeableStartAt
  consumeableEndAt
  stockedCount
  droppedCount
  droppableCount
  highlight {
    uniqueKey
    highlightTitle
    highlightDescription
    highlightImages {
      url
      type
    }
  }
  projectNumber
}
    `;
export const UserDigitalCertificateFieldFragmentDoc = gql`
    fragment UserDigitalCertificateField on UserCertificateEntity {
  uniqueKey
  nftOpenSeaUrl
  dropNftStatus
  certificate {
    uniqueKey
    name
    description
    imageUrl
    eventPlaceName
    eventStartAt
    eventEndAt
    enable
  }
  consumedAt
}
    `;
export const UserGoodRentalsFieldFragmentDoc = gql`
    fragment UserGoodRentalsField on UserEventRentalGoodsEntity {
  eventDate
  eventEndAt
  eventGoods {
    eventRentalGoodId
    userRentalGoodId
    isRented
    goodsRemaining
    good {
      id
      name
      imageUrl
    }
  }
}
    `;
export const UserSeatReservationFieldFragmentDoc = gql`
    fragment UserSeatReservationField on UserSeatReservation {
  userSeatReservation {
    uniqueKey
  }
  eventDate
  seatType
  seatRemaining
  isReserved
  isAdminCancel
  eventEndAt
}
    `;
export const UserTicketFieldFragmentDoc = gql`
    fragment UserTicketField on UserTicketEntity {
  uniqueKey
  nftOpenSeaUrl
  nftTransferExecStatus
  ticket {
    uniqueKey
    name
    description
    beforeDropDescription
    imageUrl
    eventPlaceName
    eventStartAt
    eventEndAt
    consumeableStartAt
    consumeableEndAt
    enable
    highlight {
      uniqueKey
      highlightTitle
      highlightDescription
      highlightImages {
        url
        type
      }
    }
  }
  consumedAt
}
    `;
export const UserFieldFragmentDoc = gql`
    fragment UserField on UserEntity {
  uniqueKey
  eoaAddress
}
    `;
export const CancelUserRentalGoodsDocument = gql`
    mutation cancelUserRentalGoods($userRentalGoodId: Float!, $certificateUniqueKey: String!, $ticketUniqueKey: String!) {
  cancelUserRentalGoods(
    userRentalGoodId: $userRentalGoodId
    certificateUniqueKey: $certificateUniqueKey
    ticketUniqueKey: $ticketUniqueKey
  ) {
    id
    eventRentalGoodId
    userId
  }
}
    `;
export const CancelUserSeatReservationDocument = gql`
    mutation cancelUserSeatReservation($seatReservationUniqueKey: String!, $certificateUniqueKey: String!) {
  cancelUserSeatReservation(
    seatReservationUniqueKey: $seatReservationUniqueKey
    certificateUniqueKey: $certificateUniqueKey
  ) {
    id
    uniqueKey
    eventDate
    seatType
    userId
    isAdminCancel
  }
}
    `;
export const ConsumeUserTicketDocument = gql`
    mutation consumeUserTicket($userTicketUniqueKey: String!) {
  consumeUserTicket(userTicketUniqueKey: $userTicketUniqueKey) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const CreateUserCertificateDocument = gql`
    mutation createUserCertificate($input: CreateUserCertificateInput!) {
  createUserCertificate(input: $input) {
    ...UserDigitalCertificateField
  }
}
    ${UserDigitalCertificateFieldFragmentDoc}`;
export const CreateUserGoodRentalDocument = gql`
    mutation createUserGoodRental($eventRentalGoodId: Float!) {
  createUserGoodRental(eventRentalGoodId: $eventRentalGoodId) {
    id
    eventRentalGoodId
    userId
  }
}
    `;
export const CreateUserSeatReservationDocument = gql`
    mutation createUserSeatReservation($certificateUniqueKey: String!, $input: CreateUserSeatReservationInput!) {
  createUserSeatReservation(
    certificateUniqueKey: $certificateUniqueKey
    input: $input
  ) {
    id
    uniqueKey
    eventDate
    seatType
    userId
    isAdminCancel
  }
}
    `;
export const CreateUserTicketDocument = gql`
    mutation createUserTicket($ticketUniqueKey: String!, $visitCount: Float, $awareness: String, $relationship: String, $maritalStatus: String, $childrenCount: Float, $childrenAges: String, $preferredTicketType: String) {
  createUserTicket(
    input: {ticketUniqueKey: $ticketUniqueKey, visitCount: $visitCount, awareness: $awareness, relationship: $relationship, maritalStatus: $maritalStatus, childrenCount: $childrenCount, childrenAges: $childrenAges, preferredTicketType: $preferredTicketType}
  ) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const CreateUserDocument = gql`
    mutation createUser($nickname: String!, $dateOfBirthOn: String!, $postalCode: String!, $gender: String!) {
  createUser(
    input: {nickname: $nickname, dateOfBirthOn: $dateOfBirthOn, postalCode: $postalCode, gender: $gender}
  ) {
    ...UserField
  }
}
    ${UserFieldFragmentDoc}`;
export const BenefitCommonCertificateDocument = gql`
    query benefitCommonCertificate($type: BenefitType!) {
  benefitCommonCertificate(type: $type) {
    ...BenefitCommonField
  }
}
    ${BenefitCommonFieldFragmentDoc}`;
export const DroppableUserTicketDocument = gql`
    query droppableUserTicket($ticketUniqueKey: String!) {
  droppableUserTicket(ticketUniqueKey: $ticketUniqueKey)
}
    `;
export const GetBenefitRentalGoodsDocument = gql`
    query getBenefitRentalGoods {
  getBenefitRentalGoods {
    ...BenefitsRentalGoodField
  }
}
    ${BenefitsRentalGoodFieldFragmentDoc}`;
export const GetBenefitSeatReservationDocument = gql`
    query getBenefitSeatReservation {
  getBenefitSeatReservation {
    ...BenefitSeatReservationField
  }
}
    ${BenefitSeatReservationFieldFragmentDoc}`;
export const TicketDocument = gql`
    query ticket($ticketUniqueKey: String!) {
  ticket(ticketUniqueKey: $ticketUniqueKey) {
    ...TicketField
  }
}
    ${TicketFieldFragmentDoc}`;
export const UserGetDigitalCertificateDocument = gql`
    query userGetDigitalCertificate($uniqueKey: String!) {
  userGetDigitalCertificateDetail(uniqueKey: $uniqueKey) {
    ...UserDigitalCertificateField
  }
}
    ${UserDigitalCertificateFieldFragmentDoc}`;
export const UserDigitalCertificatesDocument = gql`
    query UserDigitalCertificates {
  userGetAllDigitalCertificates {
    ...UserDigitalCertificateField
  }
}
    ${UserDigitalCertificateFieldFragmentDoc}`;
export const UserGoodRentalsDocument = gql`
    query userGoodRentals($certificateUniqueKey: String!, $ticketUniqueKey: String!) {
  userGoodRentals(
    certificateUniqueKey: $certificateUniqueKey
    ticketUniqueKey: $ticketUniqueKey
  ) {
    ...UserGoodRentalsField
  }
}
    ${UserGoodRentalsFieldFragmentDoc}`;
export const UserSeatReservationsDocument = gql`
    query userSeatReservations($certificateUniqueKey: String!) {
  userSeatReservations(certificateUniqueKey: $certificateUniqueKey) {
    ...UserSeatReservationField
  }
}
    ${UserSeatReservationFieldFragmentDoc}`;
export const UserTicketDocument = gql`
    query userTicket($userTicketUniqueKey: String!) {
  userTicket(userTicketUniqueKey: $userTicketUniqueKey) {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const UserTicketsDocument = gql`
    query userTickets {
  userTickets {
    ...UserTicketField
  }
}
    ${UserTicketFieldFragmentDoc}`;
export const UserDocument = gql`
    query user {
  user {
    ...UserField
  }
}
    ${UserFieldFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    cancelUserRentalGoods(variables: CancelUserRentalGoodsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CancelUserRentalGoodsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelUserRentalGoodsMutation>(CancelUserRentalGoodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelUserRentalGoods', 'mutation');
    },
    cancelUserSeatReservation(variables: CancelUserSeatReservationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CancelUserSeatReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelUserSeatReservationMutation>(CancelUserSeatReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelUserSeatReservation', 'mutation');
    },
    consumeUserTicket(variables: ConsumeUserTicketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ConsumeUserTicketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ConsumeUserTicketMutation>(ConsumeUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'consumeUserTicket', 'mutation');
    },
    createUserCertificate(variables: CreateUserCertificateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserCertificateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserCertificateMutation>(CreateUserCertificateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUserCertificate', 'mutation');
    },
    createUserGoodRental(variables: CreateUserGoodRentalMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserGoodRentalMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserGoodRentalMutation>(CreateUserGoodRentalDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUserGoodRental', 'mutation');
    },
    createUserSeatReservation(variables: CreateUserSeatReservationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserSeatReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserSeatReservationMutation>(CreateUserSeatReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUserSeatReservation', 'mutation');
    },
    createUserTicket(variables: CreateUserTicketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserTicketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserTicketMutation>(CreateUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUserTicket', 'mutation');
    },
    createUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUser', 'mutation');
    },
    benefitCommonCertificate(variables: BenefitCommonCertificateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BenefitCommonCertificateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BenefitCommonCertificateQuery>(BenefitCommonCertificateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'benefitCommonCertificate', 'query');
    },
    droppableUserTicket(variables: DroppableUserTicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DroppableUserTicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DroppableUserTicketQuery>(DroppableUserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'droppableUserTicket', 'query');
    },
    getBenefitRentalGoods(variables?: GetBenefitRentalGoodsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitRentalGoodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitRentalGoodsQuery>(GetBenefitRentalGoodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBenefitRentalGoods', 'query');
    },
    getBenefitSeatReservation(variables?: GetBenefitSeatReservationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitSeatReservationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitSeatReservationQuery>(GetBenefitSeatReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBenefitSeatReservation', 'query');
    },
    ticket(variables: TicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TicketQuery>(TicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ticket', 'query');
    },
    userGetDigitalCertificate(variables: UserGetDigitalCertificateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserGetDigitalCertificateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserGetDigitalCertificateQuery>(UserGetDigitalCertificateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userGetDigitalCertificate', 'query');
    },
    UserDigitalCertificates(variables?: UserDigitalCertificatesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserDigitalCertificatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserDigitalCertificatesQuery>(UserDigitalCertificatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserDigitalCertificates', 'query');
    },
    userGoodRentals(variables: UserGoodRentalsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserGoodRentalsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserGoodRentalsQuery>(UserGoodRentalsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userGoodRentals', 'query');
    },
    userSeatReservations(variables: UserSeatReservationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserSeatReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserSeatReservationsQuery>(UserSeatReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userSeatReservations', 'query');
    },
    userTicket(variables: UserTicketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserTicketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserTicketQuery>(UserTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userTicket', 'query');
    },
    userTickets(variables?: UserTicketsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserTicketsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserTicketsQuery>(UserTicketsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userTickets', 'query');
    },
    user(variables?: UserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'user', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;