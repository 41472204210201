/* tslint:disable */
/* eslint-disable */
/**
 * Nestjs Auth0 Template API
 * Nestjs Auth0 Template API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * V1AdminApi - axios parameter creator
 * @export
 */
export const V1AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} certificateUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchMintCertificate: async (certificateUniqueKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateUniqueKey' is not null or undefined
            assertParamExists('batchMintCertificate', 'certificateUniqueKey', certificateUniqueKey)
            const localVarPath = `/v1/admin/digital-certificates/{certificateUniqueKey}/mint-certificate`
                .replace(`{${"certificateUniqueKey"}}`, encodeURIComponent(String(certificateUniqueKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchMintNft: async (ticketUniqueKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUniqueKey' is not null or undefined
            assertParamExists('batchMintNft', 'ticketUniqueKey', ticketUniqueKey)
            const localVarPath = `/v1/admin/ticket/{ticketUniqueKey}/mint-nft`
                .replace(`{${"ticketUniqueKey"}}`, encodeURIComponent(String(ticketUniqueKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateCertificates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/download-template-certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/download-template-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/download-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserTickets: async (ticketUniqueKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUniqueKey' is not null or undefined
            assertParamExists('downloadUserTickets', 'ticketUniqueKey', ticketUniqueKey)
            const localVarPath = `/v1/admin/download-user-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ticketUniqueKey !== undefined) {
                localVarQueryParameter['ticketUniqueKey'] = ticketUniqueKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportListCertificates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/export/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportListUserCertificates: async (uniqueKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueKey' is not null or undefined
            assertParamExists('exportListUserCertificates', 'uniqueKey', uniqueKey)
            const localVarPath = `/v1/admin/export/user-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uniqueKey !== undefined) {
                localVarQueryParameter['uniqueKey'] = uniqueKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCertificates: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/import/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/import-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminApi - functional programming interface
 * @export
 */
export const V1AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} certificateUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchMintCertificate(certificateUniqueKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchMintCertificate(certificateUniqueKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.batchMintCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchMintNft(ticketUniqueKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchMintNft(ticketUniqueKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.batchMintNft']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemplateCertificates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemplateCertificates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadTemplateCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemplateTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemplateTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadTemplateTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUserTickets(ticketUniqueKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadUserTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportListCertificates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportListCertificates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.exportListCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportListUserCertificates(uniqueKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportListUserCertificates(uniqueKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.exportListUserCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCertificates(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCertificates(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.importCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.importTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1AdminApi - factory interface
 * @export
 */
export const V1AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} certificateUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchMintCertificate(certificateUniqueKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.batchMintCertificate(certificateUniqueKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchMintNft(ticketUniqueKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.batchMintNft(ticketUniqueKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateCertificates(options?: any): AxiosPromise<string> {
            return localVarFp.downloadTemplateCertificates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateTickets(options?: any): AxiosPromise<string> {
            return localVarFp.downloadTemplateTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets(options?: any): AxiosPromise<string> {
            return localVarFp.downloadTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserTickets(ticketUniqueKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadUserTickets(ticketUniqueKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportListCertificates(options?: any): AxiosPromise<string> {
            return localVarFp.exportListCertificates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportListUserCertificates(uniqueKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.exportListUserCertificates(uniqueKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCertificates(file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.importCertificates(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importTickets(options?: any): AxiosPromise<string> {
            return localVarFp.importTickets(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminApi - interface
 * @export
 * @interface V1AdminApi
 */
export interface V1AdminApiInterface {
    /**
     * 
     * @param {string} certificateUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    batchMintCertificate(certificateUniqueKey: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    batchMintNft(ticketUniqueKey: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadTemplateCertificates(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadTemplateTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    exportListCertificates(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} uniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    exportListUserCertificates(uniqueKey: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    importCertificates(file?: File, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    importTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * V1AdminApi - object-oriented interface
 * @export
 * @class V1AdminApi
 * @extends {BaseAPI}
 */
export class V1AdminApi extends BaseAPI implements V1AdminApiInterface {
    /**
     * 
     * @param {string} certificateUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public batchMintCertificate(certificateUniqueKey: string, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).batchMintCertificate(certificateUniqueKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public batchMintNft(ticketUniqueKey: string, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).batchMintNft(ticketUniqueKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadTemplateCertificates(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadTemplateCertificates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadTemplateTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadTemplateTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadUserTickets(ticketUniqueKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public exportListCertificates(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).exportListCertificates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public exportListUserCertificates(uniqueKey: string, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).exportListUserCertificates(uniqueKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public importCertificates(file?: File, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).importCertificates(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public importTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).importTickets(options).then((request) => request(this.axios, this.basePath));
    }
}

