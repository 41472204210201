import { UserTicketEntity } from '@/generated/graphql';
import { userTicketDetailUrl } from '@/helpers/url.helper';
import { formatDateTimeForEventStart } from '@/utils/dateTime';
import classNames from 'classnames';
import { get } from 'lodash';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

export function MinimalTicket({
  data,
  onSelected,
}: {
  data: UserTicketEntity;
  onSelected: (uniqueKey: string) => void;
}) {
  const router = useRouter();

  const ticket = get(data, 'ticket', {});
  const thumbnail = get(ticket, 'imageUrl', '');
  const isUsed = get(data, 'consumedAt');

  const isEnd = useMemo(() => {
    const now = new Date().getTime();
    const eventEnd = new Date(get(ticket, 'eventEndAt', '')).getTime();

    return now > eventEnd;
  }, [data]);

  const goToTicketDetail = () => {
    router.push(userTicketDetailUrl(get(data, 'uniqueKey')));
  };

  return (
    <div className="w-full flex flex-col rounded-lg bg-white shadow-md grayscale-0 cursor-pointer">
      <div
        className="p-4 flex justify-between items-center min-h-[69px]"
        onClick={() => {
          onSelected(get(data, 'uniqueKey'));
        }}
      >
        <div
          className={classNames('flex flex-col font-medium text-sm max-w-full whitespace-nowrap', {
            '!max-w-[70%]': isEnd || isUsed,
          })}
        >
          <span
            className="max-w-[80%] text-gray2 text-ellipsis overflow-hidden"
            title={get(ticket, 'eventPlaceName', '')}
          >
            {get(ticket, 'eventPlaceName', '')}
          </span>
          <span className={classNames('max-w-full text-bgError text-ellipsis overflow-hidden')}>
            {formatDateTimeForEventStart(get(ticket, 'eventStartAt', ''))}
          </span>
        </div>
        <div className="relative w-full h-[42px]">
          {isEnd && !isUsed && (
            <span className="z-10 absolute top-1/2 -translate-y-1/2 text-gray1 font-medium whitespace-nowrap right-0">
              試合終了
            </span>
          )}
          {isUsed && (
            <div className="z-20 absolute top-1/2 -translate-y-1/2 right-0 font-noto w-[73px] h-[23px] flex items-center justify-center -rotate-[15.63deg] bg-[#FBF0EF]/45 text-primaryRed font-bold text-xs border-primaryRed border-[0.6px] border-dashed rounded-[3px] whitespace-nowrap">
              利用済み
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray1" onClick={goToTicketDetail}>
        <img
          className={classNames('w-full h-full object-cover aspect-square text-transparent', {
            invisible: !thumbnail,
          })}
          src={thumbnail}
          alt="ticket thumbnail"
        />
      </div>
      <div className="p-6 min-h-[108px]" onClick={goToTicketDetail}>
        <h2
          className={classNames('text-xl text-primaryRed font-bold line-clamp-2', {
            '!text-gray1': isEnd,
          })}
          title={get(ticket, 'name')}
        >
          {get(ticket, 'name')}
        </h2>
      </div>
    </div>
  );
}
