import classnames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type ColorType = 'primary' | 'secondary' | 'tertiary';
type VariantType = 'solid' | 'outline';

type ButtonProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
  variant?: VariantType;
  color?: ColorType;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const getColorClassNames = (color: ColorType) => {
  switch (color) {
    case 'primary':
      return {
        solid: 'text-white bg-primaryRed',
        outline: 'text-primaryRed border border-primaryRed',
      };
    case 'secondary':
      return {
        solid: 'text-white bg-neutral-bodyText',
        outline: 'text-neutral-bodyText border border-neutral-bodyText',
      };
    case 'tertiary':
      return {
        solid: 'text-white bg-neutral-borderLine',
        outline: 'text-neutral-bodyText border border-neutral-borderLine',
      };
  }
};

export const Button = ({ children, isLoading, variant = 'solid', color = 'primary', ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={classnames(
        'font-outfit font-bold flex gap-2 justify-center items-center px-2 text-[18px]  w-full h-[71px] rounded-[15px] disabled:border disabled:text-gray1 disabled:bg-white disabled:pointer-events-none whitespace-pre-line',
        props.className,
        variant === 'solid' ? `text-white` : `bg-white`,
        getColorClassNames(color)[variant]
      )}
      disabled={isLoading || props.disabled}
    >
      {isLoading && <div className="w-6 h-6 border-4 border-t-gray1 border-gray-1/30 rounded-full animate-spin"></div>}
      {children}
    </button>
  );
};
