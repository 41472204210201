import { CustomErrorBoundaryProvider } from '../common';
import Header from './Header';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <CustomErrorBoundaryProvider>
      <div
        className={
          'relative min-h-screen min-w-80 overflow-auto flex-col items-center justify-start bg-custom-gradient'
        }
      >
        <div className={'container max-w-[393px] min-w-80 min-h-screen mx-auto gap-8 flex flex-col px-4 py-4'}>
          <Header />
          {children}
        </div>
      </div>
    </CustomErrorBoundaryProvider>
  );
};

export default Layout;
